import React, { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ImageUploadHandler from '../../utils/imageUpload';

const Dropzone = ({ name, register, setValue, onChange }) => {
  const inputRef = useRef(null);
  const [error, setError] = useState(null)

  const validateFile = (file) => {
    setError(null)

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          // Check if the image is square
          if (width === height) {
            // Check if the file size is less than 2MB
            if (file.size <= 2 * 1024 * 1024) {
              // Image is square and less than 2MB, you can proceed with the file
              onChange(file)
              setValue(file);
            } else {
              setError('Image size should be less than 2MB.');
            }
          } else {
            setError('Image should be square.');
            return;
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    validateFile(acceptedFiles[0])
  }, [name, setValue]);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: {
      'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'], 'image/webp': ['.webp']
    },
    onDrop
  });

  const files = acceptedFiles.map(file => (
    <li key={file.path}>{file.path}</li>
  ));

  return (
    <>
      <div className={`dropzone__wrapper d-flex justify-content-center align-items-center ${error ? 'error' : ''}`} >
        <div {...getRootProps({ className: 'dropzone' })} accept="image/*">
          <div>
            <input
              className="input-zone"
              {...getInputProps({ name, ref: inputRef })}
              accept="image/*"

            />
            <div className="text-center">
              <div>
                <button
                  className="dropzone-upload__btn btn"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Upload Image
                </button>
              </div>
              {isDragActive ? (
                <p className="dropzone-content mb-0">Release to drop the files here</p>
              ) : (
                <p className="dropzone-content mb-0">Or Drop a file here</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {
        error ? <p className="text-danger">{error}</p> : <ul className="text-white">{files}</ul>
      }

    </>
  );
};

export default Dropzone;
