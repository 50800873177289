import ENDPOINTS from "./endpoints";
import { HttpClient } from "./axios";
import { FormHttpClient } from "./form_axios";

const Client = {
  auth: {
    login: (data) => HttpClient.post(ENDPOINTS.LOGIN, data),
    register: (data) => HttpClient.post(ENDPOINTS.REGISTER, data),
    forgot_password: (data) => HttpClient.post(ENDPOINTS.FORGOT_PASSWORD, data),
    reset_password: (data) => HttpClient.post(ENDPOINTS.RESET_PASSWORD, data),
    request_otp: (data) => HttpClient.post(ENDPOINTS.REQUEST_OTP, data),
    verify_otp: (data) => HttpClient.post(ENDPOINTS.VERIFY_OTP, data),
    logout: (data) => HttpClient.post(ENDPOINTS.LOGOUT, data),
  },
  dashboard: {
    dashboard_details: () => HttpClient.get(ENDPOINTS.DASHBOARD_DETAILS),
    top_releases: () => HttpClient.get(ENDPOINTS.TOP_RELEASES),
    recent_transactions: () => HttpClient.get(ENDPOINTS.RECENT_TRANSACTIONS),
  },
  content: {
    content: (params) => HttpClient.get(ENDPOINTS.CONTENT, params),
    content_genre: () => HttpClient.post(ENDPOINTS.GENRE_LIST),
    content_sub_genre: () => HttpClient.post(ENDPOINTS.SUB_GENRE_LIST),
    content_language: () => HttpClient.post(ENDPOINTS.LANGUAGE_LIST),
    content_types: () => HttpClient.post(ENDPOINTS.TYPE_LIST),
    upload_rss: (data) => HttpClient.post(ENDPOINTS.UPLOAD_RSS, data),
    upload_metadata: (data) =>
      FormHttpClient.post(ENDPOINTS.UPLOAD_METADATA, data),
    rss_episodes: (data) => HttpClient.post(ENDPOINTS.RSS_EPISODES, data),
    mp3_episodes: (data) =>
      HttpClient.post(ENDPOINTS.UPLOAD_MP3_EPISODES, data),
    single_content: (data) => HttpClient.post(ENDPOINTS.SINGLE_CONTENT, data),
    fetch_rss_episodes: (id) =>
      HttpClient.get(`${ENDPOINTS.FETCH_RSS_EPISODES}/${id}`),
    author_details: () => HttpClient.get(ENDPOINTS.AUTHOR_DETAILS),
  },
  profile: {
    get_user_profile: () => HttpClient.get(ENDPOINTS.USER_PROFILE),
    update_profile: (data) =>
      HttpClient.post(ENDPOINTS.UPDATE_USER_DETAILS, data),
    change_password: (data) => HttpClient.post(ENDPOINTS.CHANGE_PASSWORD, data),
    get_bank_list: () => HttpClient.get(ENDPOINTS.GET_BANK_DETAILS),
    set_transaction_pin: (data) =>
      HttpClient.post(ENDPOINTS.SET_TRANSACTION_PIN, data),
    change_transaction_pin: (data) =>
      HttpClient.post(ENDPOINTS.CHANGE_TRANSACTION_PIN, data),
    set_bank_details: (data) =>
      HttpClient.post(ENDPOINTS.SET_BANK_DETAILS, data),
  },
  reports: {
    get_all_reports: (params) =>
      HttpClient.get(ENDPOINTS.GET_ALL_REPORTS, params),
    get_single_report: (id) =>
      HttpClient.get(`${ENDPOINTS.GET_SINGLE_REPORT}/${id}`),
    get_report_chart: (params) =>
      HttpClient.get(ENDPOINTS.GET_REPORT_CHART, params),
  },
  transactions: {
    get_bank_list: () => HttpClient.get(`${ENDPOINTS.GET_BANK_LIST}`),
    verify_bank_details: (data) =>
      HttpClient.post(`${ENDPOINTS.VERIFY_BANK}`, data),
    get_all_transactions: (params) =>
      HttpClient.get(`${ENDPOINTS.GET_ALL_TRANSACTIONS}`, params),
    get_wallet_balance: () => HttpClient.get(`${ENDPOINTS.GET_WALLET_BALANCE}`),
    get_recent_earnings: () =>
      HttpClient.get(`${ENDPOINTS.GET_RECENT_EARNINGS}`),
    get_title_earnings: (params) =>
      HttpClient.get(`${ENDPOINTS.GET_TITLE_EARNINGS}`, params),
    make_withdrawal: (data) =>
      HttpClient.post(`${ENDPOINTS.MAKE_WITHDRAWAL}`, data),
  },
  creatorsSignature: {
    unlock_creators_signature: (data) => HttpClient.post(`${ENDPOINTS.UNLOCK_CREATOR_SIGNATURE}`, data),
    sign_creators_signature: (data) => HttpClient.post(`${ENDPOINTS.SIGN_CREATOR_SIGNATURE}`, data)
  }
};

export default Client;
