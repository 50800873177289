import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { creatorsCodeValidation } from "../../utils/form_validation";
import { useMutation } from "react-query";
import Client from "../../client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../components/ButtonLoader";
import Contract from "./Contract";
import ContractLayout from "../../layout/Contract";

const ExternalCreatorsForm = () => {

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [step, setStep] = useState(1)
    const [code, setCode] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const togglePassword = () => {
        setShowPassword((prev) => (!prev))
    }
    const navigate = useNavigate()


    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(creatorsCodeValidation)
    })

    const { mutate: submitCode, isLoading } = useMutation(Client.creatorsSignature.unlock_creators_signature, {
        onSuccess: (data) => {
            if (data.error) {
                // console.log('Not successful')
                toast.error(data.error.message)
                console.log("Here is the error: ", data.error.message)
            } else {
                // navigate('/external-creators-form/external-creators-contract', { state: { code: data.code } })
                console.log('successful')
                setStep(2)
            }
        },
        onError: (error) => {
            if (error.response) {
                // console.log(error.response.data.message)
                toast.error(error.response.data.message)
            }
            else if (error) {
                toast.error(error)
                // console.error("Here is the error: ", error.data.message)
            }
            else {
                toast.error(error.message)
            }
        }
    })

    const submitHandler = (data) => {
        submitCode(data)
        setCode(data.code)
    }

    if (step === 2) {
        return <Contract code={code} setStep={setStep} resetStep1={reset}/>
    }

    return (
        <ContractLayout>
            <div className="text-white" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",
                height: "100vh", width: "100vw", minWidth: '60vw'
            }}
            >
                <form 
                    action="" 
                    style={{display: "flex", flexDirection: "column", gap: "20px", minWidth: '25%'}} 
                    className="auth-form"
                    onSubmit={handleSubmit(submitHandler)}
                >
                    <div className='mb-3'>
                        <h3 className='form-title sign-text'>Unlock Signature</h3>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "start"}}>
                        <label htmlFor="">Code to Unlock</label>
                        <input type="text" className="form-control auth-input" {...register("code")}/>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "start"}} className="form-input__container">
                        <label htmlFor="">Unlock Password</label>
                        <input type={showPassword ? 'text':"password"} className="form-control auth-input" {...register("password")} />
                        <div 
                            className='visibility-icon position-absolute' role='button' onClick={togglePassword}
                            style={{top: '63%', marginRight: '10%'}}
                        >
                                {`${showPassword ? 'HIDE' : 'SHOW'}`}
                        </div>
                    </div>

                    <button 
                        className="btn auth-btn"
                        type="submit"
                        disabled={isLoading}
                    >
                        {
                            isLoading || isSubmitting ?
                            <ButtonLoader /> : 'Submit'
                        }
                        
                    </button>
                </form>
            </div>
        </ContractLayout>
    );
}
 
export default ExternalCreatorsForm;