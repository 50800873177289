import React, { useEffect, useState } from "react";
import backArrow from "../../../assets/img/backArrow.svg";
import { useNavigate } from "react-router-dom";
import noImage from "../../../assets/img/no-image.png";
import usee from "../../../assets/img/usee.svg";
import Star from "../../../assets/img/Stareport.svg";
import Discount from "../../../assets/img/Discount.svg";
import Graph from "../../../assets/img/Graph.svg";
import Heart from "../../../assets/img/Heart.svg";
import Upload from "../../../assets/img/Upload.svg";
import sort from "../../../assets/img/filterTable.svg";
import search from "../../../assets/img/searchIcon.svg";
import filter from "../../../assets/img/filter-list.svg";
import activeFilter from "../../../assets/img/activeFilter.svg";
import downloadReport from "../../../assets/img/downloadReport.svg";
import { useParams } from "react-router-dom";
import Client from "../../../client";
import { useQuery } from "react-query";
import EmptyContent from "./Empty";
import { scrollToTop, getInitials } from "../../../utils/helpers";
import ContenttTile from "../MobileTile";
import deleteIcon from "../../../assets/img/Delete.svg";
import editIcon from "../../../assets/img/Edit.svg";
import DeleteContent from "../Delete";
import truncate from "truncate";
import Calendar from "../../../assets/img/Calendar.svg";
import Ticket from "../../../assets/img/Ticket.svg";
import moment from "moment";
import ButtonLoader from "../../../components/ButtonLoader";

const Details = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [deleteContent, setDeleteContent] = useState(null);
  const [contentDetails, setContentDetails] = useState([]);

  const { data: contents, isLoading: loading } = useQuery(
    ["content_details"],
    () => Client.content.single_content({ id: id }),
    {
      onSuccess: (data) => {
        setContentDetails(data.data[0]);
      },
      onError: (err) => {},
      enabled: Boolean(id),
    }
  );
  const [filterContent, setFilterContent] = useState(false);

  useEffect(() => {
    scrollToTop();
  }, []);

  const toggleDeleteContent = () => {
    setDeleteContent(!deleteContent);
  };

  return loading ? (
    <div
      className="d-flex align-items-center justify-content-center position-absolute"
      style={{
        height: "90%",
        width: "calc(100vw - 260px)",
      }}
    >
      <ButtonLoader />
    </div>
  ) : (
    <div>
      <div>
        <p className="page-title">
          Content/
          <span className="page-sub-title text-white">View Details</span>
        </p>

        <div className="mb-4 d-flex align-items-center">
          <div
            style={{
              marginRight: "9px",
            }}
            onClick={() => {
              navigate(-1);
            }}
            role="button"
          >
            <img src={backArrow} alt="icon" />
          </div>
          <h4 className="page-details mb-0">View Details</h4>
        </div>
      </div>
      <div className="report-details-wrapper ">
        <div className="second-row analytics-card withdraw ">
          <div className="analytics-card_details h-100">
            <div className="d-flex  justify-content-between w-100">
              <div className="d-flex w-100">
                <div className="">
                  <img
                    src={
                      contentDetails?.image_upload?.location_image ?? noImage
                    }
                    alt="content-img"
                    className="report-details__img"
                  />
                </div>
                <div className="report-content_details__wrapper w-100">
                  <div className="d-flex justify-content-between w-100">
                    <h3 className="report-content_details__title mb-0">
                      {contentDetails?.title}
                    </h3>

                    <div className="d-md-flex align-items-center  d-none">
                      <div
                        className="d-flex align-items-center content-action__cta me-3 mt-0 pe-3 mb-0"
                        style={{
                          borderRight: "1.5px solid #444",
                        }}
                        onClick={() => {
                          toggleDeleteContent();
                        }}
                      >
                        <div className="content-action__icon me-2">
                          <img src={deleteIcon} alt="icon" />
                        </div>
                        <p className="content-action_delete_text mb-0">
                          Delete
                        </p>
                      </div>

                      <div
                        className="d-flex align-items-center content-action__cta mb-0"
                        onClick={() => {
                          window.location.href = `/content/edit/${id}`;
                        }}
                      >
                        <div className="content-action__icon me-2">
                          <img src={editIcon} alt="icon" />
                        </div>
                        <p className="content-action_edit_text mb-0">Edit</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`mt-2 mb-3 content-details content-status px-2 ${
                      contentDetails?.status === 1
                        ? "live"
                        : contentDetails?.status === 2
                        ? "review"
                        : "rejected"
                    }`}
                  >
                    {contentDetails?.status === 1
                      ? "Live"
                      : contentDetails?.status === 2
                      ? "Review"
                      : "Rejected"}
                  </div>
                  <p className="report-details_stat_title mb-4">
                    {contentDetails?.genre
                      ?.map((ele, i) => ele?.genre?.title)
                      .join(", ")}
                  </p>

                  <div className="report-details_stats__wrapper w-75 d-md-flex d-none">
                    <div className="d-flex align-items-center report-details_stats__container">
                      <div className="report-details_stat_img__wrapper">
                        <img
                          src={Ticket}
                          className="report-details_stat_img"
                          alt="stat_img"
                        />
                      </div>
                      <p className="report-details_stat_title mb-0">
                        {`${contentDetails?.type?.title}`}
                      </p>
                    </div>
                    <div
                      className="d-flex align-items-center report-details_stats__container"
                      style={{
                        border: "none",
                      }}
                    >
                      <div className="report-details_stat_img__wrapper">
                        <img
                          src={Calendar}
                          className="report-details_stat_img"
                          alt="stat_img"
                        />
                      </div>
                      <p className="report-details_stat_title mb-0">
                        {moment(contentDetails?.created_at).format("L")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="report-details_stats__wrapper w-100 d-md-none d-block mt-4 mb-2 ">
              <div className="d-flex align-items-center report-details_stats__container first_stat no-border mb-3">
                <div className="report-details_stat_img__wrapper ">
                  <img
                    src={Discount}
                    className="report-details_stat_img"
                    alt="stat_img"
                  />
                </div>
                <p className="report-details_stat_title mb-0">
                  {`${
                    contentDetails?.is_paywall === "1"
                      ? "Inside Paywall"
                      : "Outside Paywall"
                  }`}
                </p>
              </div>

              <div className="d-flex mb-3">
                <div className="d-flex align-items-center report-details_stats__container ps-0 no-border">
                  <div className="report-details_stat_img__wrapper">
                    <img
                      src={Ticket}
                      className="report-details_stat_img"
                      alt="stat_img"
                    />
                  </div>
                  <p className="report-details_stat_title mb-0">
                    {`${contentDetails?.type?.title}`}
                  </p>
                </div>
              </div>

              <div className="d-flex mb-3">
                <div className="d-flex align-items-center report-details_stats__container ps-0 no-border">
                  <div className="report-details_stat_img__wrapper">
                    <img
                      src={Calendar}
                      className="report-details_stat_img"
                      alt="stat_img"
                    />
                  </div>
                  <p className="report-details_stat_title mb-0">
                    {moment(contentDetails?.created_at).format("L")}
                  </p>
                </div>
              </div>
            </div>

            <div className="d-md-none align-items-center mt-3 mb-2 d-flex">
              <div
                className="d-flex align-items-center content-action__cta me-3 mt-0 pe-3 mb-0"
                style={{
                  borderRight: "1.5px solid #444",
                }}
                onClick={() => {
                  toggleDeleteContent();
                }}
              >
                <div className="content-action__icon me-2">
                  <img src={deleteIcon} alt="icon" />
                </div>
                <p className="content-action_delete_text mb-0">Delete</p>
              </div>

              <div
                className="d-flex align-items-center content-action__cta mb-0"
                onClick={() => {
                  window.location.href = `/content/edit/${id}`;
                }}
              >
                <div className="content-action__icon me-2">
                  <img src={editIcon} alt="icon" />
                </div>
                <p className="content-action_edit_text mb-0">Edit</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="reports-card_wrapper">
        <div className="third-row analytics-card content-table withdraw ">
          {
            <>
              <div className="table-details__wrapper">
                <div className="d-md-flex d-block align-items-center justify-content-between ">
                  <div className="d-block d-md-flex align-items-center mb-0">
                    <h4 className="table-title pt-2">All Content</h4>
                    <div className="search-content__wrapper d-md-flex d-none">
                      <div className="pb-1">
                        <img src={search} alt="icon" />
                      </div>
                      <input
                        className="search-content__input"
                        placeholder="Search Episode"
                        // defaultValue={searchValue} onChange={(e) => {
                        //     setSearchValue(e.target.value.toLowerCase())

                        // }}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center" style={{gap: '10px'}}>
                    <div className=" report-mobile_search search-content__wrapper d-md-none d-flex">
                      <div className="pb-1">
                        <img src={search} alt="icon" />
                      </div>
                      <input
                        className="search-content__input"
                        placeholder="Search Episode"
                        // defaultValue={searchValue} onChange={(e) => {
                        //     setSearchValue(e.target.value.toLowerCase())

                        // }}
                      />
                    </div>
               
                    
                  {contentDetails?.type_id != 3 &&  <div className="d-flex align-items-center">
                      <button
                        className="btn create-content__btn mb-0 text-white"
                        onClick={() => {
                          navigate(`/content/details/${id}/add-chapter`);
                        }}
                      >
                        Add Chapters
                      </button>
                    </div> }
                    {/* <div className='download-report_img__wrapper d-flex align-items-center justify-content-center'>
                                                <img src={downloadReport} alt='download_report' className='download-report_img' />
                                            </div> */}
                    {filterContent ? (
                      <div
                        className="filtering-active__wrapper filter-icon__wrapper d-flex justify-content-center align-items-center"
                        onClick={() => setFilterContent(false)}
                        role="button"
                      >
                        <img role="button" src={activeFilter} alt="icon" />
                        <p className="filter-content__text pt-1 mb-1">Filter</p>
                      </div>
                    ) : (
                      <div
                        role="button"
                        className="report-mobile-filter_wrapper filter-icon__wrapper d-flex justify-content-center align-items-center"
                        onClick={() => {
                          setFilterContent(true);
                        }}
                      >
                        <img src={filter} alt="icon" />
                        <p className="filter-content__text pt-1 mb-1">Filter</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="analytics-card_details h-100 d-none d-md-block">
                {contentDetails?.type_id === 1 ||
                contentDetails?.type_id === 4 ||
                contentDetails?.type_id === 5 ? (
                  <>
                    {Boolean(contentDetails?.audio_d_list?.length) ? (
                      <div className="">
                        <table className="table mb-0 transaction-table text-white">
                          <thead className="thead-light pb-3">
                            <tr>
                              <th>
                                <div className="d-flex align-items-center">
                                  <p className="mb-0">Cover Art</p>
                                </div>
                              </th>
                              <th>
                                <div className="d-flex align-items-center">
                                  <p className="mb-0">Content Title</p>
                                  <div className="sort-icon__wrapper">
                                    <img src={sort} alt="icon" />
                                  </div>
                                </div>
                              </th>
                              {/* <th>Sub Genre.</th> */}
                              {/* <th className=''>Genre</th> */}
                              <th className="text-center">Type</th>
                              <th className="text-center">Language</th>
                              <th className="text-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <p className="mb-0 text-center">
                                    Date Uploaded
                                  </p>
                                  <div className="sort-icon__wrapper">
                                    <img src={sort} alt="icon" />
                                  </div>
                                </div>
                              </th>
                              <th className="text-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <p className="mb-0 text-center">Status</p>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          {contentDetails?.audio_d_list.map((el) => (
                            <tbody className="content-table-body">
                              <tr>
                                <td className="td-img__container">
                                  <div className="d-flex align-items-center">
                                    <div className="release-img_wrapper report-details_episode__img">
                                      <img src={noImage} alt="release-img" />
                                    </div>
                                  </div>
                                </td>
                                <td className="td-text__container ">
                                  {el?.episode_name}
                                </td>
                                {/* <td className='td-text__container '>{el?.hours_listened ?? 0}</td> */}
                                <td className="td-text__container text-center">
                                  {`${
                                    contentDetails?.type_id === 1 ||
                                    contentDetails?.type_id === 4 ||
                                    contentDetails?.type_id === 5
                                      ? "Audio Drama"
                                      : contentDetails?.type_id === 1
                                      ? "Audio Book"
                                      : "Podcast"
                                  }`}
                                </td>
                                <td className="td-text__container text-center">
                                  {truncate(
                                    el?.language
                                      ?.map(
                                        (ele, i) => ele?.language_name?.name
                                      )
                                      .join(", "),
                                    35
                                  )}
                                </td>
                                <td className="td-text__container text-center">
                                  {moment(contentDetails?.created_at).format(
                                    "L"
                                  )}
                                </td>
                                <td className="td-text__container text-center">
                                  <div
                                    className={`mt-2 mb-3  content-status px-2 ${
                                      contentDetails?.status === 1
                                        ? "live"
                                        : contentDetails?.status === 2
                                        ? "review"
                                        : "rejected"
                                    }`}
                                  >
                                    {contentDetails?.status === 1
                                      ? "Live"
                                      : contentDetails?.status === 2
                                      ? "Review"
                                      : "Rejected"}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    ) : (
                      <EmptyContent />
                    )}
                  </>
                ) : null}

                {contentDetails?.type_id === 2 && (
                  <>
                    {Boolean(contentDetails?.audio_b_list?.length) ? (
                      <div className="">
                        <table className="table mb-0 transaction-table text-white">
                          <thead className="thead-light pb-3">
                            <tr>
                              <th>
                                <div className="d-flex align-items-center">
                                  <p className="mb-0">Cover Art</p>
                                </div>
                              </th>
                              <th>
                                <div className="d-flex align-items-center">
                                  <p className="mb-0">Content Title</p>
                                  <div className="sort-icon__wrapper">
                                    <img src={sort} alt="icon" />
                                  </div>
                                </div>
                              </th>
                              {/* <th>Sub Genre.</th> */}
                              {/* <th className=''>Genre</th> */}
                              <th className="text-center">Duration</th>
                              <th className="text-center">Language</th>
                              <th className="text-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <p className="mb-0 text-center">
                                    Date Uploaded
                                  </p>
                                  <div className="sort-icon__wrapper">
                                    <img src={sort} alt="icon" />
                                  </div>
                                </div>
                              </th>
                              <th className="text-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <p className="mb-0 text-center">Status</p>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          {contentDetails?.audio_b_list.map((el) => (
                            <tbody className="content-table-body">
                              <tr>
                                <td className="td-img__container">
                                  <div className="d-flex align-items-center">
                                    <div className="release-img_wrapper report-details_episode__img">
                                      <img src={noImage} alt="release-img" />
                                    </div>
                                  </div>
                                </td>
                                <td className="td-text__container ">
                                  {el?.episode_name}
                                </td>
                                {/* <td className='td-text__container '>{el?.hours_listened ?? 0}</td> */}
                                <td className="td-text__container text-center">
                                  {el?.duration}
                                </td>
                                <td className="td-text__container text-center">
                                  {truncate(
                                    el?.language
                                      ?.map(
                                        (ele, i) => ele?.language_name?.name
                                      )
                                      .join(", "),
                                    35
                                  )}
                                </td>
                                <td className="td-text__container text-center">
                                  {moment(contentDetails?.created_at).format(
                                    "L"
                                  )}
                                </td>
                                <td className="td-text__container text-center">
                                  <div
                                    className={`mt-2 mb-3  content-status px-2 ${
                                      contentDetails?.status === 1
                                        ? "live"
                                        : contentDetails?.status === 2
                                        ? "review"
                                        : "rejected"
                                    }`}
                                  >
                                    {contentDetails?.status === 1
                                      ? "Live"
                                      : contentDetails?.status === 2
                                      ? "Review"
                                      : "Rejected"}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    ) : (
                      <EmptyContent />
                    )}
                  </>
                )}
                {contentDetails?.type_id === 3 && (
                  <>
                    {Boolean(contentDetails?.podcast_list?.length) ? (
                      <div className="">
                        <table className="table mb-0 transaction-table text-white">
                          <thead className="thead-light pb-3">
                            <tr>
                              <th>
                                <div className="d-flex align-items-center">
                                  <p className="mb-0">Cover Art</p>
                                </div>
                              </th>
                              <th>
                                <div className="d-flex align-items-center">
                                  <p className="mb-0">Content Title</p>
                                  <div className="sort-icon__wrapper">
                                    <img src={sort} alt="icon" />
                                  </div>
                                </div>
                              </th>
                              {/* <th>Sub Genre.</th> */}
                              {/* <th className=''>Genre</th> */}
                              <th className="text-center">Type</th>
                              <th className="text-center">Language</th>
                              <th className="text-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <p className="mb-0 text-center">
                                    Date Uploaded
                                  </p>
                                  <div className="sort-icon__wrapper">
                                    <img src={sort} alt="icon" />
                                  </div>
                                </div>
                              </th>
                              <th className="text-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <p className="mb-0 text-center">Status</p>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          {contentDetails?.podcast_list.map((el) => (
                            <tbody className="content-table-body">
                              <tr>
                                <td className="td-img__container">
                                  <div className="d-flex align-items-center">
                                    <div className="release-img_wrapper report-details_episode__img">
                                      <img src={noImage} alt="release-img" />
                                    </div>
                                  </div>
                                </td>
                                <td className="td-text__container ">
                                  {truncate(el?.podcast_title, 40)}
                                </td>
                                {/* <td className='td-text__container '>{el?.hours_listened ?? 0}</td> */}
                                <td className="td-text__container text-center">
                                  {`${
                                    contentDetails?.type_id === 1 ||
                                    contentDetails?.type_id === 4 ||
                                    contentDetails?.type_id === 5
                                      ? "Audio Drama"
                                      : contentDetails?.type_id === 1
                                      ? "Audio Book"
                                      : "Podcast"
                                  }`}
                                </td>
                                <td className="td-text__container text-center">
                                  {truncate(
                                    el?.language
                                      ?.map(
                                        (ele, i) => ele?.language_name?.name
                                      )
                                      .join(", "),
                                    35
                                  )}
                                </td>
                                <td className="td-text__container text-center">
                                  {moment(contentDetails?.created_at).format(
                                    "L"
                                  )}
                                </td>
                                <td className="td-text__container text-center">
                                  <div
                                    className={`mt-2 mb-3  content-status px-2 ${
                                      contentDetails?.status === 1
                                        ? "live"
                                        : contentDetails?.status === 2
                                        ? "review"
                                        : "rejected"
                                    }`}
                                  >
                                    {contentDetails?.status === 1
                                      ? "Live"
                                      : contentDetails?.status === 2
                                      ? "Review"
                                      : "Rejected"}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    ) : (
                      <EmptyContent />
                    )}
                  </>
                )}
              </div>

              <div className="d-block d-md-none report_mobile__wrapper">
                {contentDetails?.type_id === 1 ||
                contentDetails?.type_id === 4 ||
                contentDetails?.type_id === 5 ? (
                  <>
                    {contentDetails?.audio_d_list?.length > 0 ? (
                      <>
                        {contentDetails?.audio_d_list?.map((el) => (
                          <ContenttTile
                            content={el}
                            title={contentDetails}
                            type={"audio_drama"}
                          />
                        ))}
                      </>
                    ) : (
                      <EmptyContent />
                    )}
                  </>
                ) : null}
                {contentDetails?.type_id === 2 ? (
                  <>
                    {contentDetails?.audio_b_list?.length > 0 ? (
                      <>
                        {contentDetails?.audio_b_list?.map((el) => (
                          <ContenttTile
                            content={el}
                            title={contentDetails}
                            type={"audio_book"}
                          />
                        ))}
                      </>
                    ) : (
                      <EmptyContent />
                    )}
                  </>
                ) : null}

                {contentDetails?.type_id === 3 ? (
                  <>
                    {contentDetails?.podcast_list?.length > 0 ? (
                      <>
                        {contentDetails?.podcast_list?.map((el) => (
                          <ContenttTile
                            content={el}
                            title={contentDetails}
                            type={"podcast"}
                          />
                        ))}
                      </>
                    ) : (
                      <EmptyContent />
                    )}
                  </>
                ) : null}
              </div>
            </>
          }
        </div>
      </div>

      <DeleteContent
        toggleDeleteContent={toggleDeleteContent}
        deleteContent={deleteContent}
        selectedContent={contentDetails}
      />
    </div>
  );
};

export default Details;
