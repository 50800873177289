import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonLoader from "../../components/ButtonLoader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { creatorsSignatureValidation } from "../../utils/form_validation";
import { useMutation } from "react-query";
import Client from "../../client";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import Success from "../../components/SuccessAnimation";


const Contract = ({code, step, setStep, resetStep1}) => {

  useEffect(() => {
    if (!code) {
      setStep(1)
    }
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const navigate = useNavigate()
  const toggleUploadSuccess = () => {
    setUploadSuccess(!uploadSuccess);
  };
  // const [checkboxValue, setCheckboxValue] = useState(false)

  const { register, formState: {errors}, reset, handleSubmit } = useForm({
    resolver: yupResolver(creatorsSignatureValidation)
  })

  const { mutate: signContract, isLoading } = useMutation(Client.creatorsSignature.sign_creators_signature, {
    onSuccess: (data) => {
      if (data.error) {
          toast.error(data.error.message)
      } else {
          toggleUploadSuccess()
      }
    },
    onError: (error) => {
      if (error.response) return toast.error(error.response.data.error.message)
      else if (error.data) return toast.error(error.data.message)
      else return toast.error(error.message)
    }
  })

  const submitHandler = (data) => {
    signContract(data);
  }
  // console.log(code)

  // useEffect(() => {
  //   if (!code) {
  //     navigate('/external-creators-form')
  //   }
  // }, [code, navigate])

    // const [uploadSuccess, setUploadSuccess] = useState
    // const toggleUploadSuccess = () => {
    //     setUploadSuccess(!uploadSuccess);
    // };

  return (
    <div className="text-white" style={{minHeight: '100vh', overflowY: 'scroll'}}>
      <button style={{position: "fixed", bottom: '10px', right: '10px'}} className="text-white">
        Go to Top
      </button>

      <div className="third-row analytics-card content-table withdraw rss-upload--form__wrapper no-side__padding" id="contract">
        <h3 className=" copyright__text side-padding">
          LICENSING AGREEMENT WITH GENTI MEDIA
        </h3>

        <div className="no-cover_art__wrapper side-padding no-cover_art__text mt-2  text-white">
          <b>THIS LICENSING AGREEMENT</b> (this “<b>Agreement</b>”) is made{" "}
          <br />
          <b>BETWEEN</b> <br />
          <br />
          <b>GENTI MEDIA LIMITED</b> a company incorporated under the laws of
          the Federal Republic of Nigeria with registration number RC 1758391,
          whose registered office is situated at 42 Montgomery Road, 2nd Floor,
          Yaba, Lagos, Nigeria (hereinafter referred to as “<b>Genti</b>” which
          expression shall, where the context so admits, include its
          successors-in-title and assigns) of the first part;
          <br /> <br />
          <b>AND</b> <br />
          The “<b>Licensor</b>” which expression shall, where the context so
          admits, include its successors-in-title and assigns of the second
          part. <br />
          In this Agreement, Genti and the Licensor shall individually be
          referred to as <b>Party</b> and collectively as <b>“Parties”</b>.{" "}
          <br /> <br />
          <b>BACKGROUND</b> <br />
          <ol style={{ listStyleType: "upper-alpha" }}>
            <li>
              The Licensor is the author, publisher, owner or rights holder of
              the Audio Works.
            </li>
            <br />
            <li>
              The Licensor has agreed to grant Genti a non-exclusive license to
              use the Audio Works subject to the terms and restrictions set out
              in this Agreement.
            </li>
            <br />
            <li>
              {" "}
              The Parties are, therefore, desirous of entering into this
              Agreement to define the terms, conditions, rights, and obligations
              of each Party under this arrangement.
            </li>
          </ol>
          <br />
          <b>IT IS HEREBY AGREED as follows:</b> <br />
          <ol style={{ listStyleType: "none" }}>
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                1 <span style={{ marginLeft: "10px" }}>DEFINITIONS</span>
              </h6>
              <p>
                In this Agreement, unless the context otherwise requires,
                capitalised terms shall have the respective meanings given to
                them:
              </p>{" "}
              <p style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
                {" "}
                <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                  “Affiliate”
                </span>{" "}
                <span>
                  means any entity that directly or indirectly controls, is
                  controlled by, or is under common control with Genti or the
                  Licensor, as appropriate;
                </span>{" "}
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
                <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                  “Agreement”
                </span>{" "}
                <span>
                  means this Licensing Agreement including all schedules and any
                  subsequent amendment thereto;
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
                <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                  {" "}
                  “Audio Works”
                </span>{" "}
                <span>
                  {" "}
                  means the work being uploaded onto the Genti Creator Platform
                  at time of signing this Agreement;
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
                <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                  “Business Day”
                </span>{" "}
                <span>
                  {" "}
                  means a day (other than a Saturday, Sunday, or public holiday)
                  on which banks are open for business in Nigeria;
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
                <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                  “Effective Date”
                </span>{" "}
                <span>
                  means the date on which this Agreement is signed by the
                  Parties;
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
                <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                  “Licensor”
                </span>{" "}
                <span>
                  {" "}
                  means the author, publisher, owner, or rights holder of the
                  Audio Works; and{" "}
                </span>{" "}
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
                <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                  {" "}
                  “Territory”
                </span>{" "}
                <span> means the World.</span>
              </p>
              <br />
              <p className="mt-3" style={{ fontWeight: "bold" }}>
                1.2 <span style={{ marginLeft: "10px" }}>INTERPRETATION</span>
              </p>
              <p>In this Agreement, unless the context otherwise requires:</p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>1.2.1</span>{" "}
                <span>
                  headings are for convenience only and do not affect the
                  interpretation of this Agreement;
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>1.2.2</span>{" "}
                <span>
                  words importing the singular include the plural and vice
                  versa;{" "}
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>1.2.3</span>{" "}
                <span>
                  a reference to a clause, party, or schedule is a reference to
                  a clause, party, or schedule to this Agreement;{" "}
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>1.2.4</span>{" "}
                <span>
                  a reference to a Party in this Agreement or any document
                  issued in connection with this Agreement includes that Party's
                  successors and permitted assigns;{" "}
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>1.2.5</span>{" "}
                <span>
                  a reference to a document or this Agreement includes an
                  amendment or supplement to, or replacement or novation of,
                  that document or this Agreement but disregarding any
                  amendment, supplement, replacement, or novation made in breach
                  of this Agreement;
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>1.2.6</span>{" "}
                <span>
                  a reference to “it” in respect of a Party to this Agreement
                  also imports the masculine and feminine genders; and{" "}
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>1.2.7</span>{" "}
                <span>
                  a reference to a Person shall be construed to include any
                  individual, firm, body corporate (wherever incorporated),
                  government, state or agency of a state or any joint venture,
                  association, partnership, limited partnership, limited
                  liability partnership, works council or employee
                  representative body (in each case whether or not having
                  separate legal personality).{" "}
                </span>
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                2 <span style={{ marginLeft: "10px" }}>SCOPE OF AGREEMENT</span>
              </h6>

              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.1</span>{" "}
                <span>
                  The Licensor affirms that it is the rightful owner of the
                  Audio Works and has legal and indefeasible title to it.
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.2</span>{" "}
                <span>
                  The Licensor further affirms that its licensing of the Audio
                  Works to Genti is free from all encumbrances and that the
                  Licensor has full right, power and authority to enter this
                  Agreement.
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.3</span>{" "}
                <span>
                  The Licensor also affirms that the Audio Works was developed
                  with reasonable care and skill by properly qualified personnel
                  employing good quality materials, techniques and standards in
                  accordance with best industry standards
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.4</span>{" "}
                <span>
                  The Licensor fully indemnifies the Licensee against all costs,
                  expenses (including legal expenses) and losses that the
                  Licensee may incur in the event that claims and proceedings
                  are brought by third parties in respect of the ownership of
                  the Audio Works.
                </span>
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                3 <span style={{ marginLeft: "10px" }}>GRANT OF LICENCE</span>
              </h6>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>3.1</span>{" "}
                <span>
                  The Licensor hereby grants the following non-exclusive rights
                  in and to the Audio Works currently being uploaded onto the
                  Genti Creator Portal to the Licensee, during the term of this
                  Agreement and within the Territory:
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>3.1.1</span>{" "}
                <span>
                  The right to reproduce, distribute, market, display and sell
                  the Audio Works embodied in digital files as audio streams and
                  limited encrypted in-app only downloads.
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>3.1.2</span>{" "}
                <span>
                  The right to modify, reformat, encode, adapt and edit the
                  Audio Work to make the Audio Work compatible with the Genti
                  service, including but not limited to <br />
                  (a) adding Genti's intro and outro, and <br />
                  (b) removing audio elements that are adjudged by Genti to be
                  incompatible with Genti's service (e.g. playback instructions,
                  noise and interference, distortion, long pauses, ambient
                  sounds and sound effects, etc.);
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>3.1.3</span>{" "}
                <span>
                  The right to use, reproduce, display and distribute a 5-minute
                  excerpt from the Audio Work, or 10% of the total duration of
                  the Audio Work, whichever is shorter, in any media now known
                  or hereafter invented for purposes of advertising and
                  promoting the Audio Work and Genti. Any such excerpts will not
                  exceed 5 minutes in length or 10% of the total duration of the
                  Audio Work, whichever is shorter, and will be a verbatim
                  excerpt from the Audio Work (each, a "Genti Excerpt");
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>3.1.4</span>{" "}
                <span>
                  The right to use and distribute any cover art, image, graphic,
                  or any other artwork related to the Audio Work that the
                  Licensor provides to Genti (“the Artwork”) and the title of
                  the Audio Work on all Genti platforms and in any media now
                  known or hereafter invented for the purposes of marketing,
                  promoting and advertising the Audio Work and/or the Genti
                  service. Where the Licensor has the rights to said Artwork,
                  the Licensor shall provide Artwork to Genti and it must be the
                  same Artwork used for the print version of the work (where a
                  print version exists). Genti shall have the right to edit,
                  modify, reformat, or replace the Artwork to be more compatible
                  with the Genti service;
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>3.1.5</span>{" "}
                <span>
                  If the Licensor is the author or editor of the Audio Work, the
                  Licensor grants Genti the right to use its name, approved
                  likeness, and approved biographical information in any media
                  now known or hereafter invented for purposes of advertising
                  and promoting the Audio Work and the Genti service. The
                  Licensor will, promptly, on request, provide Genti an image
                  and brief written biography suitable for use by Genti in the
                  marketing of the Audio Work. Any image or biographical
                  information the Licensor provides will be deemed approved by
                  the Licensor upon submission to Genti. If the Licensor is not
                  the author or editor of the Audio Work, the Licensor agrees to
                  obtain for Genti the right to use, during the duration of this
                  Agreement, the author's (or editor's) name, likeness, and
                  biographical information in any media now known or hereafter
                  invented for purposes of advertising and promoting the Audio
                  Work and Genti;
                </span>
              </p>{" "}
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>3.1.6</span>{" "}
                <span>
                  The right to sublicense any of the rights granted to it under
                  this Agreement to any of its Affiliates or to any third party
                  and the Licensor may permit any sublicensee to sublicense the
                  rights Genti grants to it. Genti will remain responsible for
                  each sublicensee's compliance with this Agreement; and{" "}
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>3.1.7</span>{" "}
                <span>
                  Those rights that may not be expressly granted hereunder that
                  permit the Licensee to carry on its business and operate the
                  service as intended by the Parties under this Agreement.{" "}
                </span>
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                4 <span style={{ marginLeft: "10px" }}>DURATION</span>
              </h6>

              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>4.1</span>{" "}
                <span>
                  This Agreement shall become effective for an initial period of{" "}
                  <span style={{ fontWeight: "bold" }}>3 years</span> commencing
                  on the Effective Date (“Initial Term”)
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>4.2</span>{" "}
                <span>
                  After the Initial Term, this Agreement will renew
                  automatically for additional 1 (one) year terms (each a
                  “Subsequent Term”) unless either party provides notice of
                  termination or renegotiation to the other party at least 90
                  days prior to the end of the Initial Term or the
                  then-Subsequent Term in accordance with the notice provisions
                  provided above.
                </span>
              </p>
            </li>
            <br />

            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                5 <span style={{ marginLeft: "10px" }}>PAYMENT</span>
              </h6>

              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>5.1</span>{" "}
                <span>
                  As full consideration of the non-exclusive license granted by
                  the Licensor, Genti will pay the Licensor royalties on its
                  sales of the Audio Work as also set forth in Schedule 1.
                </span>
              </p>
            </li>
            <br />

            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                6{" "}
                <span style={{ marginLeft: "10px" }}>
                  COPYRIGHT AND OWNERSHIP
                </span>
              </h6>

              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>6.1</span>{" "}
                <span>
                  As between the Licensor and Genti, except for the license the
                  Licensor grants to Genti in this Agreement, the Licensor
                  retains all right, title, and interest in and to the Audio
                  Work, including the copyright in the Audio Work. All rights in
                  the Audio Work not granted in this Agreement to Genti are
                  expressly reserved by the Licensor.{" "}
                </span>
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                7{" "}
                <span style={{ marginLeft: "10px" }}>
                  REPRESENTATIONS, WARRANTIES, AND INDEMNITY
                </span>
              </h6>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>7.1</span>{" "}
                <span>The Licensor represents and warrants to Genti that:</span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>7.1.1</span>{" "}
                <span>
                  the entry into and performance of the terms and conditions of
                  this Agreement does not and shall not contravene or conflict
                  with its memorandum and articles of association, any law,
                  statute, regulation or other instrument binding on it or any
                  of its assets, or any Agreement or document to which it is a
                  party or is binding on it or any of its assets;
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>7.1.2</span>{" "}
                <span>
                  it holds and is in compliance with all necessary certificates,
                  licenses, permits, consents or other authorisations in and to
                  the Audio Works;
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>7.1.3</span>{" "}
                <span>
                  it has all requisite power, capacity and authority to enter
                  into this Agreement and to consummate the transaction
                  contemplated hereby and the execution and delivery of this
                  Agreement and the consummation of the transaction contemplated
                  hereby have been duly authorised by all necessary action on
                  its part, (or its board of directors or similar governing
                  body, as applicable), and no other action or proceeding is
                  necessary to authorise the execution and delivery of this
                  Agreement and the consummation of the transaction contemplated
                  hereby;
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>7.1.4</span>{" "}
                <span>
                  this Agreement constitutes and expresses its legal, valid, and
                  binding obligation and is enforceable against it in accordance
                  with the conditions and terms herein stipulated;
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>7.1.5</span>{" "}
                <span>
                  it will neither do nor allow anything to be done that may
                  prejudice the interest of the other party under this
                  Agreement;
                </span>
              </p>{" "}
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>7.1.6</span>{" "}
                <span>
                  there are no actions, suits, arbitration, government
                  investigation, audit or other proceedings pending, and or to
                  the best of its knowledge, threatened against it capable of
                  undermining its capacity to execute this Agreement and or
                  consummate the transaction to which it relates;
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>7.1.7</span>{" "}
                <span>
                  it will not do or allow anything to be done that may prejudice
                  the interest of Genti under this Agreement; and
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>7.1.8</span>{" "}
                <span>
                  the Audio Work is in no way whatsoever an infringement of any
                  existing copyright or any rights of any third party and that
                  it contains nothing obscene or libelous
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>7.2</span>{" "}
                <span style={{ fontWeight: "bold" }}>Indemnity</span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>7.2.1</span>{" "}
                <span>
                  The Licensor hereby agrees to indemnify Genti against all and
                  any claims, charges, damages, demands, liabilities, judgments,
                  costs, loss, penalties, and or fees whatsoever which Genti may
                  suffer in consequence of any of the warranties above mentioned
                  being untrue, incorrect, misleading or which may arise out of
                  the negligent or intentional acts or omissions of the Licensor
                  or their clients, their customers, their agents, employees or
                  contractors or except to the extent the same is caused solely
                  by the unauthorised act or omission (including without
                  limitation, fraud or misrepresentation) negligence or
                  misconduct of the Licensor, its authorised agents or employee;
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>7.2.2</span>{" "}
                <span>
                  The Licensor will be solely responsible for, and will pay, any
                  third parties any royalties that they are owed with respect to
                  the exercise of the rights granted under this Agreement; and
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>7.2.3</span>{" "}
                <span>
                  The Licensor further agrees that it shall be liable and
                  indemnify Genti in full against all penalties or sanctions
                  that may be levied on Genti by any regulatory authority as a
                  result of the failure of the Licensor to obtain the requisite
                  regulatory permits or licenses.
                </span>
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                8{" "}
                <span style={{ marginLeft: "10px" }}>
                  CONFIDENTIALITY & NON-DISCLOSURE
                </span>
              </h6>

              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>8.1</span>{" "}
                <span>
                  All information and documents obtained by any Party
                  (“Receiving Party”) or its employees, consultants or agents
                  which are not published or otherwise publicly available shall
                  be kept confidential and not disclosed to third parties or the
                  public. This restriction shall not apply to any information
                  disclosed by a Party to their respective legal advisers, or to
                  a third party to the extent that this is required, by any
                  court of competent jurisdiction, or by a governmental or
                  regulatory authority in Nigeria PROVIDED that not less than 3
                  (three) Business Days' notice in writing is first given to the
                  disclosing Party (“Disclosing Party”). This covenant shall
                  survive termination of this Agreement.
                </span>{" "}
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>8.2</span>{" "}
                <span>
                  Each Party shall protect the confidential information
                  disclosed pursuant to the provisions of this agreement using
                  the same standard of care that the Receiving Party applies to
                  safeguard its own proprietary, secret, or confidential
                  information and that the information shall be stored and
                  handled in such a way as to prevent any unauthorized
                  disclosure thereof. For purposes of this clause, the Receiving
                  Party's professional advisers and employees, directors or
                  managers shall be deemed to be acting in the event of a
                  breach, as that Party's duly authorised agents.
                </span>
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                9 <span style={{ marginLeft: "10px" }}>TERMINATION</span>
              </h6>
              <p>
                This Agreement may be terminated in the circumstances and manner
                stipulated hereunder:
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>9.1</span>{" "}
                <span>
                  by either Party, upon giving 30 (thirty) days' written notice
                  to the other Party, without prejudice to any rights and
                  obligations which have already accrued to the Licensor under
                  this Agreement.{" "}
                </span>{" "}
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>9.2</span>{" "}
                <span>
                  by either Party, upon willful default by the other Party to
                  perform any of its obligations under this Agreement if such
                  default is not remedied within 15 (fifteen) days after written
                  notice by the non-defaulting Party.{" "}
                </span>
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                10 <span style={{ marginLeft: "10px" }}>NOTICES</span>
              </h6>
              <p>
                {" "}
                Any notice or other communication or correspondence to be given
                under this Agreement must be in writing and shall be delivered
                personally or sent by prepaid post or courier or by facsimile
                transmission or by electronic mail. The address for service of
                each Party hereto shall be:
              </p>
              <br />
              <p style={{ fontWeight: "bold" }}>For Genti:</p>
              <br />
              <p style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
                <span style={{ marginRight: "10px" }}>Attention</span>{" "}
                <span style={{ fontWeight: "bold" }}> Genti Media Limited</span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
                <span style={{ marginRight: "10px" }}>Address</span>{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  42 Montgomery Road <br />
                  2nd Floor – Civic Hive
                  <br />
                  Yaba, Lagos, Nigeria.
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
                <span style={{ marginRight: "10px" }}>E-Mail:</span>{" "}
                <span>support@gentimedia.com</span>
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                11{" "}
                <span style={{ marginLeft: "10px" }}>
                  ENTIRE AGREEMENT AND GOOD FAITH
                </span>
              </h6>

              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>11.1</span>{" "}
                <span>
                  This Agreement constitutes the entire agreement between the
                  Parties hereto with respect to the matters dealt with herein
                  and supersedes any previous agreement between the Parties in
                  relation to such matters. Each of the Parties hereto hereby
                  acknowledge that in entering into this Agreement, it has not
                  relied on any representation or warranty save as expressly set
                  out herein or in any document referred to herein.
                </span>{" "}
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>11.2</span>{" "}
                <span>
                  Each of the Parties hereto undertake with the other to do all
                  things reasonably within its power which are necessary or
                  desirable to give effect to the spirit and intent of this
                  Agreement.
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>11.3</span>{" "}
                <span>
                  No failure to exercise and no delay in exercising on the part
                  of the any of the Parties hereto, any right, power or
                  privilege hereunder shall operate as a waiver thereof nor
                  shall any single or partial exercise of any right, power or
                  privilege preclude any other or further exercise thereof or
                  the exercise of any other right, power, or privilege. The
                  rights and remedies provided in this Agreement are cumulative
                  and not exclusive of any rights or remedies otherwise provided
                  by law.
                </span>
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                12{" "}
                <span style={{ marginLeft: "10px" }}>
                  SEVERABILITY AND AMENDMENTS
                </span>
              </h6>

              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>12.1</span>{" "}
                <span>
                  If any term or provision of this Agreement is determined to be
                  illegal, unenforceable, or invalid in whole or in part for any
                  reason, such illegal, unenforceable, or invalid provision or
                  part(s) thereof shall be struck from this Agreement, and such
                  provision shall not affect the legality, enforceability, or
                  validity of the remainder of this Agreement, and the provision
                  struck off shall be replaced, to the extent possible, with a
                  legal, enforceable, and valid provision that is as similar in
                  tenor to the provision struck off as is legally possible.
                </span>{" "}
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>11.2</span>{" "}
                <span>
                  No variation, amendment, waiver, or cancellation of this
                  Agreement shall be effective unless reduced to writing and
                  signed by the Parties.
                </span>
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                13{" "}
                <span style={{ marginLeft: "10px" }}>THIRD-PARTY RIGHTS</span>
              </h6>

              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>13.1</span>{" "}
                <span>
                  A person who is not a party to this Agreement shall not have
                  any rights under or in connection with it.{" "}
                </span>{" "}
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                14 <span style={{ marginLeft: "10px" }}>FORCE MAJEURE</span>
              </h6>

              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>14.1</span>{" "}
                <span>
                  Any delay or failure to comply with or breach of any of the
                  terms and conditions of this Agreement if occasioned by or
                  resulting from an act of God or public enemy, fire, explosion,
                  earthquake, perils of the sea, flood, storm or other adverse
                  weather conditions, war (declared or undeclared), civil war,
                  revolution, civil commotion or other civil strife, riot,
                  strikes, blockade, embargo, sanctions, pandemics, epidemics,
                  act of any government or other authority, compliance with
                  government orders, demands or regulations, or any
                  circumstances of like or different nature beyond the
                  reasonable control of the Party so failing, will not be deemed
                  to be a breach of this Agreement nor will it subject any party
                  to any liability to another Party.{" "}
                </span>{" "}
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>14.2</span>{" "}
                <span>
                  Should a Party be prevented from carrying out its contractual
                  obligations by force majeure lasting continuously for a period
                  of 15 (fifteen) Business Days, the Parties will consult with
                  one another regarding the future implementation of this
                  Agreement. If no mutually acceptable arrangement is arrived at
                  within a period of 30 (thirty) Business Days thereafter, any
                  Party will be entitled to terminate the Agreement forthwith on
                  written notice.{" "}
                </span>{" "}
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                15{" "}
                <span style={{ marginLeft: "10px" }}>
                  GOVERNING LAW & DISPUTE RESOLUTION
                </span>
              </h6>

              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>15.1</span>{" "}
                <span>
                  This Agreement shall be governed by and construed in
                  accordance with Nigerian Law.
                </span>{" "}
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>15.2</span>{" "}
                <span>
                  The Parties shall endeavour to resolve all disputes arising
                  out of or in connection with this Agreement, including any
                  question regarding its existence, enforceability, validity,
                  interpretation, or termination (“
                  <span style={{ fontWeight: "bold" }}>Dispute</span>”) by
                  mutual consultation. Any Dispute, which cannot be mutually
                  resolved by the Parties, shall be referred to and finally
                  resolved by arbitration under the Arbitration and Mediation
                  Act 2023 ("<span style={{ fontWeight: "bold" }}>AMA</span>"),
                  which rules are deemed to be incorporated by reference into
                  this clause.
                </span>{" "}
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>15.3</span>{" "}
                <span>
                  The arbitral tribunal shall consist of one arbitrator who
                  shall either be a legal practitioner admitted to practice and
                  in practice for at least 15 (fifteen) years and who shall be
                  appointed by the President Chartered Institute of Arbitrators,
                  UK (Nigeria branch) in accordance with the AMA.{" "}
                </span>{" "}
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>15.4</span>{" "}
                <span>
                  The seat, or legal place, of arbitration shall always be, and
                  all arbitration proceedings shall be held in, Lagos, Nigeria.
                  If the parties to the Dispute so agree, any arbitration
                  proceeding may be held in such other venue as may be mutually
                  convenient.{" "}
                </span>{" "}
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>15.5</span>{" "}
                <span>
                  Each Party shall bear its own costs and expenses in relation
                  to the arbitration proceedings unless the arbitral award
                  states otherwise.
                </span>{" "}
              </p>
            </li>
            <br />

            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                16 <span style={{ marginLeft: "10px" }}>AGREEMENT</span>
              </h6>

              <p>
                The Licensor acknowledges that it has read this Agreement,
                understands it, and by signing up on Genti's self-service
                creator portal at https://creator.gentimedia.com, the Licensor
                hereby agrees to the terms and conditions contained in this
                Agreement.
              </p>
            </li>
            <br />
          </ol>
          <h5
            style={{
              fontWeight: "bold",
              marginTop: "50px",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            SCHEDULE 1
          </h5>
          <h5
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            PAYMENT TERMS
          </h5>
          <ol style={{ listStyleType: "none" }}>
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                1 <span style={{ marginLeft: "10px" }}>ROYALTY TERMS</span>
              </h6>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>1.1</span>{" "}
                <span>
                  These Audio work Royalty Payment Terms and Procedures
                  ("Royalty Terms") set forth the terms by which Genti will
                  calculate and pay royalty payments owed to the Licensor in
                  connection with Genti's distribution of the Audio work
                  ("Royalties").
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>1.2</span>{" "}
                <span>
                  These Royalty Terms form a part of the Licensors Audio Work
                  License Agreement with Genti ("Agreement"). Capitalized terms
                  used in these Royalty Terms but not defined have the meaning
                  assigned to them in the Agreement.
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>1.3</span>{" "}
                <span style={{ fontWeight: "bold" }}>Royalty Calculation </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>1.3.1</span>{" "}
                <span>
                  Genti will pay the Licensor 50% of the Value of Coins (as
                  defined in clause 1.3.3 below) used by Genti's users to unlock
                  and listen to the Licensor's content across Genti's
                  platform(s) (less any applicable sales or use taxes, excise
                  taxes, value-added taxes, and duties).
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>1.3.2</span>{" "}
                <span>
                  Coins shall represent the in-platform currency on Genti's
                  platform and may be purchased by Genti's users or unlocked by
                  watching reward ads on the Genti Platform.
                </span>
              </p>
              <p
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 10fr" }}
              >
                <span></span>
                <span style={{ marginRight: "10px" }}>1.3.3</span>{" "}
                <span>
                  The Value of Coins shall be determined by the weighted average
                  value of each coin type and shall be as reported during each
                  reporting period. The weighted average value shall never be
                  less than the effective value in the cheapest coin bundle.
                </span>
              </p>
              <p>
                Notwithstanding the above provisions, Genti shall retain the
                right to review and determine the value of each coin at all
                times. This includes the authority to adjust the valuation
                methodology, if deemed necessary, to reflect changes in market
                conditions or other relevant factors. Any such adjustments made
                by Genti shall be communicated to the Licensor in a timely
                manner.
              </p>
            </li>
            <br />
            <li>
              <h6 className="mt-3" style={{ fontWeight: "bold" }}>
                2{" "}
                <span style={{ marginLeft: "10px" }}>
                  ROYALTY STATEMENTS AND PAYMENTS
                </span>
              </h6>

              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.1</span>{" "}
                <span>
                  The Licensor's Royalty shall be stored on the Licensor's
                  wallet on Genti's platform until a withdrawal request is made
                  in the appropriate manner on Genti's platform or by notice to
                  Genti. The Licensor shall have the discretion to request
                  payment of accrued Royalty at any time in accordance with the
                  Royalty Terms.
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.2</span>{" "}
                <span>
                  Genti will provide the Licensor with a statement of Royalty
                  accrued, together with a check or direct deposit in the amount
                  of any Royalty due, on a twice-a-year accounting schedule
                  (First period ending in June and second period ending in
                  December).
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.3</span>{" "}
                <span>
                  Royalties will accrue and be paid in the respective currency
                  of the Coins used for unlocking content through a payment
                  channel to be decided by Genti.
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.4</span>{" "}
                <span>
                  The Licensor may receive Royalties in US Dollar provided only
                  that the Licensor has given Genti notice and provided Genti
                  with a valid US bank account information and all necessary
                  information as may be requested by Genti to facilitate the
                  payment of Dollar Royalties to the Licensor.
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.5</span>{" "}
                <span>
                  For withdrawal of Naira royalties, Genti will facilitate
                  direct deposits supported by the Paystack payment system or a
                  similarly licensed payment service provider in Nigeria.
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.6</span>{" "}
                <span>
                  The Licensor acknowledges that Genti reserves the right to
                  change the mode and system of Royalty Payments under this
                  Agreement. Genti may, at its sole discretion, introduce
                  alternative options to facilitate payment of Royalties to the
                  Licensor.{" "}
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.7</span>{" "}
                <span>
                  The Licensor is solely responsible for its own compliance with
                  all applicable tax laws and the payment of any applicable
                  taxes. The Licensor hereby agrees to bear all transfer cost
                  relating to the payment of Royalties.
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.8</span>{" "}
                <span>
                  Genti may deduct or withhold any taxes that Genti may be
                  legally obligated to deduct or withhold from any amounts
                  payable to the Licensor, and payments, as reduced by such
                  deductions or withholdings, will constitute full payment and
                  settlement to the Licensor. As may be required, the Licensor
                  shall provide Genti with the Licensor's tax information (i.e.
                  tax identification number) to enable Genti to satisfy any
                  withholding tax obligation further to this Agreement.{" "}
                </span>
              </p>
              <p style={{ display: "grid", gridTemplateColumns: "1fr 11fr" }}>
                <span style={{ marginRight: "10px" }}>2.9</span>{" "}
                <span>
                  Any Royalty statements Genti provides to the Licensor will be
                  considered final and incontestable 3 months from the date the
                  statements are provided by Genti if the Licensor does not
                  object to the statements within that period of time.
                </span>
              </p>
            </li>
          </ol>
          <form 
            action=""
            className="rss-uploaded_content__form"
            style={{display: "flex", flexDirection: "column", gap: "20px", width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' ,marginTop: '100px', justifyContent: 'center', alignItems: 'center'}} 
            onSubmit={handleSubmit(submitHandler)}
          >
            <div>
              <h3>LICENSE AGREEMENT FORM</h3>
              <a href="#contract" className="text-white" style={{fontSize: '14px'}}>Go through license agreement above</a>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "start", width: '60%'}}>
              <label htmlFor="">Code</label>
              <input type="text" className="form-control" {...register('code')} value={code} disabled />
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "start", width: '60%'}} className="form-input__container">
              <label htmlFor="">Signed Name</label>
              <input type="text" className="form-control" {...register('signedName')} />
            </div>
            <div
              className="no-cover_art__wrapper d-flex justify-content-center align-items-center"
            >
              <div className="">
                <input
                  type="checkbox"
                  name="checkbox"
                  className="cstm-checkbox__input"
                  role="button"
                  {...register('checkbox')}
                />
              </div>
              <p className="mb-0 no-cover_art__text text-white" style={{width: '60%', }}>
                I acknowledge that I am the rightful copyright owner or have
                authorization from the copyright owner
              </p>
            </div>

            <button 
                className="btn auth-btn"
                type="submit"
                disabled={isLoading}
                style={{width: '60%'}}
            >
                {
                    isLoading ?
                    <ButtonLoader /> : 'Submit'
                }
                
            </button>
          </form>
        </div>
        <Modal
        isOpen={uploadSuccess}
        toggle={toggleUploadSuccess}
        className="modal-dialog-centered"
        contentClassName="upload-success__modal_content-wrapper"
      >
        <div className="analytics-card upload-success__modal">
          <div className="upload-succes__icon__container">
            <Success />
          </div>
          <h3 className="upload-success__text">Congratulations! </h3>
          <p className="upload-success__sub-text">
            You have successfully signed the creators' contract
          </p>
          <button
            className="btn auth-btn w-100 upload-success__button"
            type="submit"
            onClick={() => {
              setStep(1)
              resetStep1()
            }}
          >
            {/* {
                        isLoading ?
                            <ButtonLoader /> :
                            'Sign in'
                    } */}
            Done
          </button>
        </div>
      </Modal>
        {/* <div className="fill__container no-border"></div> */}
        {/* <div className="d-flex justify-content-end rss-upload-button__wrapper">
          <div className="side-padding">
            <button
              className="btn auth-btn "
              type="submit"
              onClick={() => {
                // toggleUploadSuccess();
              }}
            >
              Upload
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Contract;
