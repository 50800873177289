import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Signup from './pages/Auth/Register'
import Signin from './pages/Auth/Login'
import OTP from './pages/Auth/OTP'
import ResetOTP from './pages/Auth/VerifyResetOTP'
import ForgotPassword from './pages/Auth/ForgotPassword'
import ResetPassword from './pages/Auth/PasswordReset'
import Dashboard from './pages/Dashboard'
import Content from './pages/Content'
import UploadContent from './pages/Content/Upload'
import EditContent from './pages/Content/Edit'
import ProtectedRoute from './components/ProtectedRoute'
import Report from './pages/Report'
import ReportDetails from './pages/Report/Details'
import Transactions from './pages/Transactions'
import Withdrawal from './pages/Transactions/Withdrawal'
import RecentEarnings from './pages/Transactions/RecentEarnings'
import Profile from './pages/Profile'
import Guide from './pages/Guide'
import FAQ from './pages/FAQ'
import LoginOTP from './pages/Auth/LoginOTP'
import ContentDetails from './pages/Content/Details'
import Contract from './pages/Contract/Contract'
import ExternalCreatorsForm from './pages/Contract'
import ContractLayout from './layout/Contract'
import AddChapter from './pages/Content/Add_Chapter'



const RouterConfig = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={<ProtectedRoute />} >
                    <Route exact path='/' element={<Dashboard />} />
                    <Route exact path='/content' element={<Content />} />
                    <Route exact path='/content/upload' element={<UploadContent />} />
                    <Route exact path='/content/edit/:id' element={<EditContent />} />
                    <Route exact path='/content/details/:id' element={<ContentDetails />} />
                    <Route path='/content/details/:id/add-chapter' element={<AddChapter />} />
                    <Route exact path='/report' element={<Report />} />
                    <Route exact path='/report/details/:id' element={<ReportDetails />} />
                    <Route exact path='/transactions' element={<Transactions />} />
                    <Route exact path='/transactions/withdrawal' element={<Withdrawal />} />
                    <Route exact path='/transactions/recent-earnings' element={<RecentEarnings />} />
                    <Route exact path='/profile' element={<Profile />} />
                    <Route exact path='/guide' element={<Guide />} />
                    {/* <Route exact path='/guide/creator-faq' element={<FAQ />} /> */}
                </Route >

                <Route exact path='/auth/register' element={<Signup />} />
                <Route exact path='/auth/login' element={<Signin />} />
                <Route exact path='/auth/verify-login' element={<LoginOTP />} />
                <Route exact path='/auth/OTP' element={<OTP />} />
                <Route exact path='/auth/verify-OTP' element={<ResetOTP />} />
                <Route exact path='/auth/reset-password' element={<ResetPassword />} />
                <Route exact path='/auth/forgot-password' element={<ForgotPassword />} />
                <Route exact path='/faq' element={<FAQ />} />
                <Route exact path='/unlock-signature' element={<ExternalCreatorsForm />} />

            </Routes>
        </BrowserRouter>
    )
}
export default RouterConfig
