import React, { useState } from "react";
import SavedAccount from "./SavedAccount";
import FirstTime from "./FirstTime";
import { useQuery } from "react-query";
import Client from "../../../client";
import { toast } from "react-toastify";

const Withdraw = () => {
  const [userType, setUserType] = useState(0);
  const [bankList, setBankList] = useState([]);
  const [section, setSection] = useState("empty");
  const [wallet_balance, setWalletBalance] = useState(null);
  const [banks, setBanks] = useState([]);

  useQuery(["bank-details"], () => Client.profile.get_bank_list(), {
    onSuccess: (data) => {
      setBankList(data.data);
      if (data?.data === null) {
        setUserType(0);
      } else {
        setUserType(1);
      }
    },
  });

  const { refetch } = useQuery(
    ["wallet_balance"],
    () => Client.transactions.get_wallet_balance(),
    {
      onSuccess: (data) => {
        setWalletBalance(data.data["2"]);
      },
    }
  );

  useQuery(["bank-list"], () => Client.transactions.get_bank_list(), {
    onSuccess: (data) => {
      setBanks(data.data);
    },
  });

  return (
    <>
      {userType === 1 ? (
         <SavedAccount
         setUserType={setUserType}
         bankList={bankList}
         walletBalance={wallet_balance}
         refetchWalletBalance={refetch}
       />
      ) : (
        <>
        {userType}
        <FirstTime
          walletBalance={wallet_balance}
          banks={banks}
          refetchWalletBalance={refetch}
          userType={userType}
        />
        </>
      )}
    </>
  );
};

export default Withdraw;
