const ENDPOINTS = {
  LOGIN: "/auth/email-login",
  REGISTER: "/auth/create-user",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password",
  REQUEST_OTP: "/auth/send-email-otp",
  VERIFY_OTP: "/auth/verify-otp",
  LOGOUT: "/auth/logout",
  DASHBOARD_DETAILS: "/get-dashboard-details",
  TOP_RELEASES: "/get-top-releases",
  RECENT_TRANSACTIONS: "/get-recent-transactions",
  CONTENT: "/get-content",
  GENRE_LIST: "/get-genre?all=1",
  SUB_GENRE_LIST: "/get-sub-genre?all=1",
  LANGUAGE_LIST: "/get-language?all=1",
  TYPE_LIST: "/get-types?all=1",
  UPLOAD_RSS: "/upload-rss",
  UPLOAD_METADATA: "/upload-title-metadata",
  RSS_EPISODES: "/get-episodes-from-rss",
  USER_PROFILE: "/auth/get-user-profile",
  UPDATE_USER_DETAILS: "/auth/update-user-profile",
  CHANGE_PASSWORD: "/auth/reset-password-cp",
  SET_TRANSACTION_PIN: "/auth/set-transaction-pin",
  CHANGE_TRANSACTION_PIN: "/auth/set-transaction-pin",
  SET_BANK_DETAILS: "/auth/set-bank-details",
  GET_BANK_DETAILS: "/auth/get-bank-details",
  GET_ALL_REPORTS: "/reports",
  GET_SINGLE_REPORT: "/report",
  GET_REPORT_CHART: "/get-report-charts",
  GET_PRESIGN_URL: "/creator-presigned-content-url",
  UPLOAD_MP3_EPISODES: "/upload-episodes",
  SINGLE_CONTENT: "/single-content",
  GET_BANK_LIST: "/get-bank-list",
  VERIFY_BANK: "/verify-account-details",
  GET_ALL_TRANSACTIONS: "/transactions?currency_group=3",
  GET_RECENT_EARNINGS: "/get-recent-earnings",
  GET_TITLE_EARNINGS: "/get-title-earnings?currency_group=3",
  GET_WALLET_BALANCE: "/get-wallet-balance?currency_group=3",
  MAKE_WITHDRAWAL: "/make-withdrawal",
  UPLOAD_IMAGE: "/upload-images",
  FETCH_RSS_EPISODES: "fetch-rss-episodes",
  AUTHOR_DETAILS: "/get-authors-details",
  UNLOCK_CREATOR_SIGNATURE: "/guest/unlock-creator-signature",
  SIGN_CREATOR_SIGNATURE: "/guest/sign-creator-signature"
};

export default ENDPOINTS;
