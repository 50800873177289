import * as yup from "yup";
import Password from "../pages/Profile/Password";

export const loginValidation = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email")
    .required("Email is required")
    /*eslint no-useless-escape: 0*/
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid Email"
    ),
  password: yup.string().required("Password is required"),
});

export const registerValidation = yup.object().shape({
  name: yup.string().required("Name is required"),
  phone: yup
    .string()
    .required("Phone is required")
    .matches(/^(?=.{8,}).*$/, "Invalid Phone number"),
  // country_id: yup
  //     .string()
  //     .required('Country code is required'),
  // mobile_number: yup
  //     .string()
  //     .required('Phone number is required'),
  email: yup
    .string()
    .email("Invalid Email")
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid Email"
    ),

  /*eslint no-useless-escape: 0*/

  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*!=]).*$/,
      "Password must have at least one capital letter, one small letter,one number, one special character, and minimum of 6 letters "
    ),
});

export const forgotPasswordValidation = yup.object().shape({
  verify: yup
    .string()
    .email("Invalid Email")
    .required("Email is required")
    /*eslint no-useless-escape: 0*/
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid Email"
    ),
});

export const resetPasswordValidation = yup.object().shape({
  new_password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*!=]).*$/,
      "Password must have at least one capital letter, one small letter,one number, one special character, and minimum of 6 letters "
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords doesn't match"),
});

export const setPinValidation = yup.object().shape({
  transaction_pin: yup
    .string()
    .required("Field is required")
    .matches(/^(?=.{4,})(?=.*[0-9]).*$/, "Pin must be 4 digits"),
  confirm_transaction_pin: yup
    .string()
    .oneOf([yup.ref("transaction_pin"), null], "Pins doesn't match"),
});

export const changePinValidation = yup.object().shape({
  old_transaction_pin: yup.string().required("Field is required"),
  transaction_pin: yup
    .string()
    .required("Field is required")
    .matches(/^(?=.{4,})(?=.*[0-9]).*$/, "Pin must be 4 digits"),
  confirm_transaction_pin: yup
    .string()
    .oneOf([yup.ref("transaction_pin"), null], "Pins doesn't match"),
});

export const changePasswordValidation = yup.object().shape({
  old_password: yup.string().required("Password is required"),
  new_password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*!=]).*$/,
      "Password must have at least one capital letter, one small letter,one number, one special character, and minimum of 6 letters "
    ),
  confirm_password: yup
    .string()
    .required("Password is required")
    .oneOf([yup.ref("new_password"), null], "Passwords doesn't match"),
});

export const rssUploadValidation = yup.object().shape({
  rss: yup
    .string()
    .required("RSS link is required")
    .matches(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
      "Invalid RSS url"
    ),
  type_id: yup.string().required("RSS type is required"),
});

export const metadataValidation = yup.object().shape({
  author_description: yup.string().required("Author description is required"),
  authors: yup.string().required("Authors is required"),
  description: yup.string().required("Description is required"),
  genre: yup.string().required("Genre is required"),
  language: yup.string().required("Language is required"),
  narrators: yup.string().required("Narrator is required"),
  // publishers: yup
  //     .string()
  //     .required('Publisher is required'),
  sub_genre: yup.string().required("Sub genre is required"),
  title: yup.string().required("Title is required"),
  type_id: yup.string().required("Content type is required"),
  // is_paywall: yup
  //     .string()
  //     .required('Paywall is required')
});

export const bankDetailsValidation = yup.object().shape({
  bank: yup.object().required("Bank is required"),
  account_number: yup.string().required("Account number is required"),
});

export const firstTimeWithdrawalValidation = yup.object().shape({
  bank: yup.object().required("Bank is required"),
  account_number: yup.string().required("Account number is required"),
  amount: yup.string().required("Amount is required"),
});

export const withdrawalValidation = yup.object().shape({
  amount: yup.string().required("Amount is required"),
});

export const creatorsCodeValidation = yup.object().shape({
  code: yup.string().required("Code is required"),
  password: yup.string().required("Password is required")
})

export const creatorsSignatureValidation = yup.object().shape({
  code: yup.string().required('Code is required'),
  signedName: yup.string().required('Signed Name is required'),
  checkbox: yup.boolean().isTrue('Checkbox must be checked')
})